.email_field {
  -ms-word-break: break-all; /* Be VERY careful with this, breaks normal words wh_erever */
  word-break: break-all; /* Non standard for webkit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  width: 250px;
}
.CoachList div {
  width: 200px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoachList {
  padding: 17px;
  display: flex;
  align-items: center;
  color: white;
  box-sizing: border-box;
}

.CoachList:nth-child(even) {
  background-color: lightgray;
  color: black;
}

.CoachList:nth-child(odd) {
  color: black;
}

.MuiButton-label {
  color: black;
}
