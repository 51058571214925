.topbar__container {
  height: 80px;
}
.topbar {
  height: 100%;
  align-items: center;

  background-color: var(--primarycolor);
}

.topbar div {
  color: white;
}

.topbar div:hover {
  background-color: var(--hovercolor);
  cursor: pointer;
}
