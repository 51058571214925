.signup {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  width: 300px;
  min-height: 100vh;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
}

.input-field {
  border-radius: 0.3rem;
  border: 0.12rem solid grey;
  height: 1.5rem;
  margin: 0.3rem 0;
  width: 100%;
}

.form-radiogroup {
  display: flex;
  margin: 0.3rem 0.2rem;
  /*      padding: 0.2rem; */
  flex-direction: column;
}

.signup__heading {
  color: gray;
  font-weight: 600;
}

.signup__button {
  margin-top: 2.5rem;
  border-radius: 0.5rem;
  width: 100%;
  cursor: pointer;

  border: 0.12rem solid transparent;
  background-color: var(--primarycolor);
  color: white;
  padding: 10px 20px;
}
